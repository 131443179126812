import Layout from '@/components/Layout/Layout';
import { useData } from '@/shared/providers/DataProvider';
import { ConfigStoryblok, PageStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import clsx from 'clsx';
import { useEffect } from 'react';
import styles from './Page.module.scss';

type PageProps = {
    blok: PageStoryblok;
    config: ConfigStoryblok;
};

const Page = (props: PageProps) => {
    const { pageMainColor, body, navbar } = props.blok || {};
    const firstPageSection = body?.[0];
    const darkTheme = firstPageSection?.theme === 'Dark' || firstPageSection?.isDark;
    const { setDataForId, slugs, data } = useData();

    useEffect(() => {
        setDataForId('lastPageSlugs', slugs);
        setDataForId('lastPageColor', pageMainColor);
        setDataForId('currentPageColor', pageMainColor);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slugs]);
    return (
        <Layout blok={props.config} anchorLinks={navbar || []} animationType='page'>
            <main
                id="page"
                className={clsx(styles.root, {
                    [styles.existsNavbar]: navbar?.length && navbar?.length > 0,
                    [styles.darkTheme]: darkTheme,
                })}
            >
                {body?.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
            </main>
        </Layout>
    );
};
export default Page;
